<template>
  <div class="text-dark">
    <b-modal id="modal-response" v-model="modalVisible" centered class="p-3">
      <div slot="modal-header" class="d-flex align-items-center justify-content-between p-2 w-100">
        <span class="h4 mb-0 text-primary">Rincian Pembayaran</span>
        <span @click="modalVisible = false" class="h4 mb-0 text-primary text-hover">x</span>
      </div>
      <div class="d-flex align-items-center justify-content-between p-2">
        <span>Total Harga</span>
        <span >Rp.{{ toCurrency(subTotal) }}</span>
      </div>
      <div v-if="discountTransaction && discountTransaction.length > 0" >
        <div v-for="(item, index) in discountTransaction" :key="index">
          <div class="d-flex align-items-center justify-content-between p-2">
            <span> {{ item.name }} </span>
            <span style="color: #FF0000"> - Rp {{ toCurrency(item.promotion_value) }} </span>
          </div>
        </div>
      </div>
      <div v-if="voucherUsed && voucherUsed.length > 0" >
        <div v-for="(item, index) in voucherUsed" :key="index">
          <div class="d-flex align-items-center justify-content-between p-2">
            <span> {{ item.name }} </span>
            <span style="color: #FF0000"> - Rp {{ toCurrency(item.promotion_value) }} </span>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between p-2">
        <span>Biaya Pengiriman</span>
        <span >Rp.{{ toCurrency(totalShippingPayment) }}</span>
      </div>
      <div v-if="loyaltyUsage" class="d-flex align-items-center justify-content-between p-2">
        <span>Coin yang digunakan</span>
        <span style="color: #FF0000;">- {{ toCurrency(loyaltyUsage) }}</span>
      </div>
      <div v-if="depositReturUsage" class="d-flex align-items-center justify-content-between p-2">
        <span>Deposit retur yang digunakan</span>
        <span style="color: #FF0000;">- {{ toCurrency(depositReturUsage) }}</span>
      </div>
      <div slot="modal-footer" class="d-flex align-items-center justify-content-between w-100 p-2">
        <span>Total Tagihan</span>
        <span >Rp.{{ data.total }}</span>
      </div>
    </b-modal>
    <h2 class="mb-5">Pembayaran</h2>
    <div class="d-flex justify-content-center">
      <div class="card align-items-center w-75" style="padding: 24px 100px">
        <div class="card-body col">
          <div v-if="loyaltyUsage" class="d-flex mb-5" style="gap: 0px 20px">
            <div class="d-flex align-items-center">
              <img src="https://ik.imagekit.io/fepca/220309_Desain_Icon_Kino_Siap_-_Loyalty_Point_1_d0yHZ4-ch.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1653948298528" />
              <div class="ml-2" style="color: #015CA1">Coin</div>
            </div>
            <span>{{ toCurrency(loyaltyUsage) }}</span>
          </div>
          <div v-if="depositReturUsage" class="d-flex mb-5" style="gap: 0px 20px">
            <div class="d-flex align-items-center">
              <img src="@/resources/images/return.png" style="filter: brightness(0%);"/>
              <div class="ml-2">Deposit Retur</div>
            </div>
            <span>{{ toCurrency(depositReturUsage) }}</span>
          </div>
          <div class="d-flex justify-content-between mb-5">
            <div class="d-flex flex-column">
              <span>Total Pembayaran</span>
              <div class="d-flex align-items-center justify">
                <span >Rp.{{ data.total }}</span>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-end">
              <span class="text-hover text-primary button-link" @click="toggleModal">Lihat Rincian Pembayaran</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PaymentTutorial',
  components: {
  },
  props: ['loyaltyUsage', 'depositReturUsage', 'subTotal', 'dataFetch'],
  data: function () {
    return {
      id_params: this.$route.params.order_id,
      data: {},
      activeKey: '',
      modalVisible: false,
      countDown: moment().format('HH:mm:ss'),
    }
  },
  computed: {
    time: function () {
      const time = this.countDown
      const resultTime = moment.utc(time).format('HH:mm:ss')
      return this.countDown <= 0 ? '00:00:00' : resultTime
    },
    totalShippingPayment() {
      const dataOrder = this.dataFetch
      return (dataOrder?.shipment_data?.insurance_cost?.value || 0) + (dataOrder?.shipment_data?.shipping_cost?.value || 0)
    },
    discountTransaction() {
      return this.data?.promotions?.discounts || []
    },
    voucherUsed() {
      return this.data?.promotions?.vouchers || []
    },
  },
  methods: {
    getDifferenceInHours(expiredDate, currentDate) {
      const differentTime = expiredDate - currentDate
      const checkIfExpired = differentTime <= 0 ? 0 : moment(expiredDate) - moment(currentDate)
      // const result = moment.utc(checkIfExpired).format('HH:mm:ss')
      return checkIfExpired
    },
    getOrderDetail() {
      this.$store.dispatch('purchase/GETINTRUCTIONSPAYMENT', {
        channel_id: this.$store.state.user.user_data.channel_id,
        order_id: this.id_params,
      })
        .then(({ data }) => {
          const splitDate = data.expired_at.split(' ')
          const endDate = {
            day: moment(splitDate[0]).format('dddd'),
            date: moment(splitDate[0]).format('DD MMMM YYYY'),
            hour: moment(splitDate[1], 'HH:mm:ss').format('HH:mm'),
            countDown: [splitDate[0], splitDate[1]].join(' '),
          }
          const expiredDate = moment(endDate.countDown).format('YYYY-MM-DD HH:mm:ss')
          const hours = this.getDifferenceInHours(new Date(expiredDate).getTime(), new Date().getTime())
          this.countDown = hours
          const newInstructions = (data && data.instructions) || (data && data.instructions && data.instructions.instructions) || {}
          return this.$store.dispatch('purchase/GETINVOICEDETAIL', {
            order_id: this.id_params,
          }).then((res) => {
            const result = res.data
            this.data = {
              ...data,
              ...result,
              newInstructions,
              expired: `${endDate.day}, ${endDate.date} - ${endDate.hour}`,
              total: Intl.NumberFormat('en-US').format(result.gross_amount),
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    changeActiveKey(key) {
      this.activeKey = key
    },
    toggleModal() {
      this.modalVisible = !this.modal
    },
    onError() {
      return this.$notification.error({
        message: 'Copy to Clipboard Failed',
      })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
  mounted() {
    this.getOrderDetail()
    setInterval(() => {
      this.countDown = moment(this.countDown).subtract(1, 'seconds')
    }, 1000)
  },
  watch: {
    // this.countDownTimer()
  },
}
</script>

<style scoped>
.ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.text-hover:hover {
  color: #015CA1;
  cursor: pointer;
}
.button-link {
  font-size: 14px;
}

.button-primary {
  padding: 4px 24px;
  border-radius: 8px;
  font-size: 16px;
}
</style>
