<template>
  <div class="text-dark" v-if="!isExpired">
    <b-modal id="modal-response" v-model="modalVisible" centered class="p-3">
      <div slot="modal-header" class="d-flex align-items-center justify-content-between p-2 w-100">
        <span class="h4 mb-0 text-primary">Rincian Pembayaran</span>
        <span @click="modalVisible = false" class="h4 mb-0 text-primary text-hover">x</span>
      </div>
      <div class="d-flex align-items-center justify-content-between p-2">
        <span>Total Harga ({{data && data.invoice_items && data.invoice_items.length}} Produk)</span>
        <span class="price-subtotal">Rp.{{ toCurrency(subTotal) }}</span>
      </div>
      <div v-if="discountTransaction && discountTransaction.length > 0" >
        <div v-for="(item, index) in discountTransaction" :key="index">
          <div class="d-flex align-items-center justify-content-between p-2">
            <span> {{ item.name }} </span>
            <span style="color: #FF0000"> - Rp {{ toCurrency(item.promotion_value) }} </span>
          </div>
        </div>
      </div>
      <div v-if="voucherUsed && voucherUsed.length > 0" >
        <div v-for="(item, index) in voucherUsed" :key="index">
          <div class="d-flex align-items-center justify-content-between p-2">
            <span> {{ item.name }} </span>
            <span style="color: #FF0000"> - Rp {{ toCurrency(item.promotion_value) }} </span>
          </div>
        </div>
      </div>

      <div v-if="data.payment.term === 'CAD'" class="d-flex align-items-center justify-content-between p-2">
        <span>Credit Limit</span>
        <span style="color: #F5831F;"> - Rp. {{ toCurrency(dataFetch.payment_data.credit_limit_usage) }}</span>
      </div>
      <div v-if="loyaltyUsage && loyaltyUsage !== 0" class="d-flex align-items-center justify-content-between p-2">
        <span>Coin yang digunakan</span>
        <span style="color: #000000;">- {{ toCurrency(loyaltyUsage) }}</span>
      </div>
      <div v-if="dataFetch?.shipment_data?.shipping_cost && dataFetch?.shipment_data?.shipping_cost?.value !== 0" class="d-flex align-items-center justify-content-between p-2">
        <span>Biaya Pengiriman</span>
        <span class="price-subtotal">Rp.{{ toCurrency(dataFetch?.shipment_data?.shipping_cost?.value + dataFetch?.shipment_data?.insurance_cost?.value) }}</span>
      </div>
      <div slot="modal-footer" class="d-flex align-items-center justify-content-between w-100 p-2">
        <span>Total Tagihan</span>
        <span class="price-total">{{ data.payment.term === 'CAD' ? 'Rp.' + (toCurrency(parseFloat(data.total.replace(/,/g, "")) - dataFetch.payment_data.credit_limit_usage)) : 'Rp.' + data.total }}</span>
      </div>
    </b-modal>
    <div class="card-payment p-3 align-items-center">
      <div v-if="data.payment_type === 'EWALLET' && data?.payment_method?.qr_code" class="d-flex flex-column align-items-center">
        <!-- {{ data.payment_method.qr_code}} -->
        <span>{{ $t('purchase.detail.detail_transaction_qr') }}</span>
        <img :src="data && data.payment_method && data.payment_method.qr_code" width="225.99px" />
        <!-- {{data.payment_method.qr_code}} -->
      </div>
      <div v-if="data.payment_type === 'EWALLET' && data?.payment_method?.deep_link_redirect" class="d-flex flex-column align-items-center mb-3 mt-3">
        <a :href="data.payment_method.deep_link_redirect">
          <div class="btn action-button btn-detil-transaksi">
            {{ $t('purchase.detail.pay_via_web_link') }}
          </div>
        </a>
      </div>
      <div class="mb-3" style="text-align: center">
        <span class="mr-1 font-weight-normal">Pembayaran Anda akan berakhir pada</span>
        <span class="font-weight-bold">{{ data && data.expired }}</span>
      </div>
      <div class="card-header-tutorial-payment mb-2 text-primary">
        <div>
          <span class="h1" style="letter-spacing: 2px;">{{ time }}</span>
          <!-- <span class="h1">{{ countDown }}</span> -->
        </div>
        <div class="d-flex justify-content-between align-items-center" style="font-size: 13px">
          <span>Jam</span>
          <span>Menit</span>
          <span>Detik</span>
        </div>
      </div>
      <div>
        <div class="card-body-tutorial-payment mb-4 mt-5">
          <div class="">
          <div v-if="data.payment.term === 'CAD'" class="d-flex align-items-center p-2 mb-2">
            <CreditIcon />
            <span class="text-primary mr-1 ml-2"><b>Credit Limit </b></span>
            <span><b> - Rp. {{ toCurrency(dataFetch.payment_data.credit_limit_usage) }}</b></span>
          </div>
          <div class="d-flex align-items-center justify-content-between px-2 py-3">
            <div>
              {{ data.payment_name }}
            </div>
            <img :src="data.payment_image"
              :alt="data.payment_image && data.payment_image" width="60px" />
          </div>
          <a-divider class="my-0"/>
          </div>
          <div class="p-2">
            <div class="d-flex flex-column flex-column">
              <!-- {{data}} -->
              <div class="d-flex align-items-center justify-content-between p-2">
                <div>
                  <span v-if="data.payment_type === 'BANK_TRANSFER'">Bank Transfer</span>
                  <span v-else-if="data.payment_type === 'EWALLET'">{{ data.payment_name }}</span>
                  <span v-else>Nomor Virtual Account</span>
                  <div v-if="data.payment_type !== 'EWALLET'" class="flex-row d-flex align-items-center">
                    <b class="se">{{ data && data.payment_method && data.payment_method.account_number }}</b>
                  </div>
                </div>
                <div>
                  <div v-if="data.payment_type !== 'EWALLET'" class="flex-row d-flex align-items-center">
                    <a class="copy-text ml-1 mt-1 text-right"
                      v-clipboard:copy="data && data.payment_method && data.payment_method.account_number"
                      v-clipboard:success="onCopy" v-clipboard:error="onError" style="width: 50px">
                      <a-col :xs="6" :sm="6" :md="6">
                        <copy-icon />
                      </a-col>
                      <b class="text-primary">salin</b>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column p-2">
              <div>
                <span>Total Pembayaran</span>
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <b>{{ data.payment.term === 'CAD' ? 'Rp.' + (toCurrency(parseFloat(data.total.replace(/,/g, "")) - dataFetch.payment_data.credit_limit_usage)) : 'Rp.' + data.total }}</b>
                  </div>
                  <div class="d-flex">
                    <a class="copy-text ml-3 mt-1 text-right" v-clipboard:copy="copyGrandTotal" v-clipboard:success="onCopy"
                      v-clipboard:error="onError" style="width: 50px">
                      <a-col :xs="6" :sm="6" :md="6">
                        <copy-icon />
                      </a-col>
                      <b class="text-primary">salin</b>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end p-2">
              <b class="text-hover text-primary" @click="toggleModal">Lihat Rincian</b>
            </div>
          </div>
        </div>
        <div class="p-2" v-if="data && data.instructions && data.instructions.list">
          <span class="title-instruction">Instruksi Pembayaran</span>
          <template>
            <a-collapse class="my-3 border-0 text-dark bg-white" expand-icon-position="right" :activeKey='activeKey' accordion>
              <a-collapse-panel :key="key" class="text-dark" :header="title"
                v-for="({ title, description }, key) in (data.instructions.list || (data.instructions.list) || {})" >
                <template #extra>
                  <img :src="data.payment_image" width="60px" class="mr-4" />
                </template>
                <ol v-for="(data, i) in description" :key="i"
                  class="text-secondary d-flex flex-row align-items-center mb-0">
                  {{ i + 1 }}.<li class="border-0 list-group-item" v-html="data"></li>
                </ol>
              </a-collapse-panel>
            </a-collapse>
          </template>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="card-payment p-3 align-items-center text-center">
      <h4>Payment Expired</h4>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import CopyIcon from '@/components/Icons/Copy.vue'
import CreditIcon from '@/components/Icons/Credit.vue'
// import RandomQR from '@/components/Icons/RandomQR.vue'

export default {
  name: 'PaymentTutorial',
  components: {
    CopyIcon,
    CreditIcon,
    // RandomQR,
  },
  props: ['loyaltyUsage', 'subTotal', 'title', 'dataFetch'],
  data: function () {
    return {
      id_params: this.$route.params.order_id,
      data: {},
      activeKey: '',
      modalVisible: false,
      countDown: moment().format('HH:mm:ss'),
      discountTransaction: [],
      voucherUsed: [],
      isExpired: false,
    }
  },
  computed: {
    time: function () {
      const time = this.countDown
      const hours = Math.floor((time / (1000 * 60 * 60)))
      const resultTime = moment.utc(time).format('mm:ss')
      return this.countDown <= 0 ? '00:00:00' : `${hours < 10 ? `0${hours}` : hours}:${resultTime}`
    },
    copyGrandTotal() {
      return this.data.payment.term === 'CAD' ? parseFloat(this.data.total.replace(/,/g, '') - this.dataFetch.payment_data.credit_limit_usage) : this.data.total.replace(/,/g, '')
    },
  },
  methods: {
    getDifferenceInHours(expiredDate, currentDate) {
      const differentTime = expiredDate - currentDate
      const checkIfExpired = differentTime <= 0 ? 0 : moment(expiredDate) - moment(currentDate)
      // const result = moment.utc(checkIfExpired).format('HH:mm:ss')
      return checkIfExpired
    },
    getOrderDetail() {
      this.$store.dispatch('purchase/GETINTRUCTIONSPAYMENT', {
        channel_id: this.$store.state.user.user_data.channel_id,
        order_id: this.id_params,
      })
        .then(({ data }) => {
          let termUnit = data.payment_method.term_unit.replaceAll('(', '')
          termUnit = termUnit.replaceAll(')', '')
          termUnit = termUnit.toLowerCase()
          const startDateData = moment(data.created_at)

          let endDateData = null
          if (data.payment_type === 'EWALLET') {
            endDateData = startDateData.add(15, 'minutes')
          } else {
            endDateData = startDateData.add(data.payment_method.term_value, termUnit)
          }

          const endDate = {
            day: moment(endDateData).format('dddd'),
            date: moment(endDateData).format('DD MMMM YYYY'),
            hour: moment(endDateData).format('HH:mm'),
            countDown: moment(endDateData).format('YYYY-MM-DD HH:mm:ss'),
          }
          const expiredDate = moment(endDate.countDown).format('YYYY-MM-DD HH:mm:ss')
          const hours = this.getDifferenceInHours(new Date(expiredDate).getTime(), new Date().getTime())
          this.countDown = hours
          const newInstructions = (data && data.instructions) || (data && data.instructions && data.instructions.instructions) || {}
          return this.$store.dispatch('purchase/GETINVOICEDETAIL', {
            order_id: this.id_params,
          }).then((res) => {
            const result = res.data
            this.data = {
              ...data,
              ...result,
              newInstructions,
              expired: `${endDate.day}, ${endDate.date} - ${endDate.hour}`,
              total: Intl.NumberFormat('en-US').format(result.gross_amount),
            }
            if (this.data.payment_type === 'EWALLET' && this.data?.payment_method?.deep_link_redirect && this.data?.payment_method?.qr_code === null) {
              window.location.href = this.data?.payment_method?.deep_link_redirect
            }
            this.isExpired = moment().isAfter(moment(endDateData))
            this.discountTransaction = result?.promotions?.discounts
            this.voucherUsed = result?.promotions?.vouchers
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    toggleModal() {
      this.modalVisible = !this.modal
    },
    onCopy() {
      return this.$notification.success({
        message: 'Copy to Clipboard Success',
      })
    },
    onError() {
      return this.$notification.error({
        message: 'Copy to Clipboard Failed',
      })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
  mounted() {
    this.getOrderDetail()
    setInterval(() => {
      this.countDown = moment(this.countDown).subtract(1, 'seconds')
    }, 1000)
  },
}
</script>

<style scoped>
.price-subtotal {
  color: var(--biz-primary-100);
}
.price-total {
  color: var(--biz-brand);
}
.ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.text-hover:hover {
  color: #015CA1;
  cursor: pointer;
}
.card-payment {
  max-width: 900px;
  margin: auto;
}
.card-header-tutorial-payment {
  max-width: 160px;
  margin: auto;
  color: #ED1C24!important;
}
.title-instruction {
  font-weight: 600;
  font-size: 16px;
}
.card-body-tutorial-payment {
  border: 1px solid #F3F3F3;
  max-width: 500px;
  margin: auto;
}
.text-primary {
  color: #FF685E!important;
}
</style>
